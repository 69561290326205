import React from "react"
import { PageProps } from "gatsby"

import PageMetadata from "../../components/page/page-metadata"
import DocumentationLayout from "../../components/documentation/documentation-layout"
import { useFocusedApiResource } from "../../context/focused-api-resource"
import { HeadingOne } from "../../components/common/typography"
import { MDXProvider } from "../../components/mdx/mdx-provider"
import { useApiResources } from "../../hooks/api-resources"
import { ResourceRenderer } from "../../components/api-reference/resource-renderer"

const ApiResourcesPage: React.FC<PageProps> = ({ location }) => {
  const resources = useApiResources()
  const { clearFocus } = useFocusedApiResource()

  React.useEffect(() => {
    return () => {
      clearFocus()
    }
  }, [clearFocus])

  const resourceItems = resources.map((resource, index) => (
    <React.Fragment key={resource.name}>
      <ResourceRenderer resource={resource} />
      {index < resources.length - 1 && <hr className="border-neutral-200 my-32" />}
    </React.Fragment>
  ))

  return (
    <div className="mx-6 md:mx-12 xl:mx-20">
      <MDXProvider>
        <PageMetadata title="Resources" />
        <DocumentationLayout path={location.pathname}>
          <HeadingOne unanchored>Resources</HeadingOne>

          <div className="mt-8">{resourceItems}</div>
        </DocumentationLayout>
      </MDXProvider>
    </div>
  )
}

export default ApiResourcesPage
