import React from "react"
import { Link } from "gatsby"
import kebabCase from "lodash/kebabCase"
import { OpenAPIV3 } from "openapi-types"

import { ApiResponse } from "../../../../plugins/gatsby-source-openapi3"
import { Table, TableCell, TableHeading, TableRow } from "../../common/common-components"
import { HeadingFour } from "../../common/typography"
import { OpenApiUtils } from "../../../../plugins/gatsby-source-openapi3/openapi-utils"

type Props = {
  errorResponses: ApiResponse[]
}

export const ErrorsTable = React.memo<Props>(({ errorResponses }) => {
  const errors = parseErrorsFromResponses(errorResponses)

  if (errors.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col">
      <HeadingFour unanchored>Errors</HeadingFour>
      <Table className="w-full table-fixed">
        <thead>
          <TableRow>
            <TableHeading className="text-left w-1/3">Error</TableHeading>
            <TableHeading className="text-left">Description</TableHeading>
          </TableRow>
        </thead>
        <tbody>
          {errors.map((error) => (
            <TableRow key={error.name}>
              <TableCell>
                <Link to={`/api/errors${error.anchor}`}>
                  <div className="text-tangerine underline">{error.name}</div>
                </Link>
              </TableCell>
              <TableCell>{error.description}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </div>
  )
})

type OperationError = {
  name: string
  anchor: string
  description: string
}

function parseErrorsFromResponses(errorResponses: ApiResponse[]): OperationError[] {
  const errorMessages = new Map<string, string>()

  for (const response of errorResponses) {
    if (response.content == null) {
      continue
    }

    const content = JSON.parse(response.content) as NonNullable<OpenAPIV3.ResponseObject["content"]>
    const { examples } = Object.values(content)[0]

    if (examples == null || OpenApiUtils.isReferenceObject(examples)) {
      continue
    }

    for (const example of Object.values(examples)) {
      if (
        OpenApiUtils.isReferenceObject(example) ||
        example.summary == null ||
        example.description == null ||
        errorMessages.has(example.summary)
      ) {
        continue
      }

      errorMessages.set(example.summary, example.description)
    }
  }

  const errors: OperationError[] = []

  for (const [name, description] of errorMessages) {
    errors.push({
      name,
      description,
      anchor: `#${kebabCase(name)}`,
    })
  }

  return errors
}
